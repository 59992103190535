'use client';

import { usePathname } from 'next/navigation';
import Link from '@/components/Link';
import IconBox from '@/components/IconBox';
import { useState } from 'react';
import OrgsDropdown from './OrgsDropdown';
import { redirectToAuthUrl } from '@/actions/auth-url';
import ProjectsDropdown from './ProjectsDropdown';
import getProjectMenuItems from './ProjectMenu/getProjectMenuItems';

function MenuLink({
  href,
  icon,
  color,
  title,
  description,
  active,
  expanded,
  small,
}) {
  return (
    <Link
      href={href}
      className={`group flex ${small ? 'gap-[18px]' : 'gap-3.5'} items-center ${
        active
          ? 'opacity-100 text-1'
          : 'opacity-60 hover:opacity-100 text-3 hover:text-1 transition-colors'
      } py-2 pl-0.5 cursor-pointer`}
      title={description}>
      <IconBox size={small ? 'sm' : 'md'} color={color} icon={icon} />
      {expanded ? (
        <span
          className={`hidden lg:block max-w-[15rem] 2xl:max-w-[20rem] whitespace-nowrap text-ellipsis overflow-hidden ${small ? 'text-xs xl:text-sm' : 'text-sm xl:text-base'} leading-6`}>
          {title}
        </span>
      ) : null}
    </Link>
  );
}

function MenuIcon({ className }) {
  return (
    <svg
      className={className}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect x="24" y="32" width="16" height="8" fill="#66D9EF" />
      <rect y="32" width="16" height="8" fill="white" />
      <rect y="16" width="40" height="8" fill="white" />
      <rect width="16" height="8" fill="#F92672" />
      <rect x="24" width="16" height="8" fill="#A6E22E" />
    </svg>
  );
}

const secondaryItems = [
  {
    title: 'Account',
    description: 'View and manage your account information',
    icon: 'account',
    href: '/account',
  },
  {
    title: 'Organisation',
    description: 'View and manage your organisation',
    icon: 'organisation',
    href: '/dashboard',
  },
  {
    title: 'Docs',
    description: 'View API reference and how-to guides',
    icon: 'docs',
    href: '/docs',
  },
  {
    title: 'Support',
    description: 'Get help and support from the team',
    icon: 'help',
    href: '/support',
  },
];

const orgItems = [
  // {
  //   title: 'Dashboard',
  //   description: "Get an overview of your organisation's activity",
  //   icon: 'dashboard',
  //   color: 'purple',
  //   href: `/dashboard`
  // },
  {
    title: 'Projects',
    description: "View and manage your organisation's projects",
    icon: 'project',
    color: 'magenta',
    href: `/projects`,
  },
  {
    title: 'Team',
    description: 'Invite and manage access for your team members',
    icon: 'team',
    color: 'yellow',
    href: `/team`,
  },
  {
    title: 'Billing',
    description: 'View and manage your billing and payment information',
    icon: 'payment',
    color: 'green',
    href: `/billing`,
  },
  {
    title: 'Settings',
    description:
      "Update your organisation's name, description, and other settings",
    icon: 'settings',
    color: 'blue',
    href: `/settings`,
    secondary: true,
  },
];

export default function AppMenu({ org, orgs = [], project, projects = [] }) {
  const pathname = usePathname();

  const menuItems = project
    ? getProjectMenuItems({ project_alias: project.alias })
    : orgItems;

  // let userPreference = null;
  // if (localStorage?.getItem('menuExpanded')) {
  //   userPreference = localStorage.getItem('menuExpanded') === 'true';
  // }
  // const [isExpanded, setIsExpanded] = useState(
  //   userPreference === false ? false : true,
  // );
  const isExpanded = false;
  const [isOpen, setIsOpen] = useState(isExpanded);

  // function handleExpand() {
  //   setIsExpanded(true);
  //   setIsOpen(true);
  //   localStorage.setItem('menuExpanded', 'true');
  // }

  // function handleCollapse() {
  //   setIsExpanded(false);
  //   setIsOpen(false);
  //   localStorage.setItem('menuExpanded', 'false');
  // }

  return (
    <nav
      className={`h-screen overflow-x-hidden overflow-y-auto flex flex-col items-stretch justify-start px-4 py-6 bg-dark-100 ${
        isOpen ? 'w-64' : 'w-16'
      } transition-all duration-100`}
      onMouseEnter={() => (isOpen ? null : setIsOpen(true))}
      // onMouseLeave={() => (isExpanded ? null : setIsOpen(false))}
      onMouseLeave={() => setIsOpen(false)}>
      <img
        src="https://ittybit-app.ittybitcdn.com/ittybit-logo-2025-rgb-pixel%402x.png"
        alt="ittybit"
        className={`h-8 w-24 ${isOpen ? 'block' : 'hidden'}`}
      />

      <MenuIcon className={`h-6 w-6 mb-2 ${isOpen ? 'hidden' : 'block'}`} />

      <div className="mt-12">
        {org ? (
          <OrgsDropdown
            action={redirectToAuthUrl}
            orgs={orgs}
            org={org}
            expanded={isOpen}
          />
        ) : null}
        {project ? (
          <ProjectsDropdown
            projects={projects}
            project={project}
            expanded={isOpen}
          />
        ) : null}
      </div>

      <ul className="flex flex-col items-stretch justify-start mt-2">
        {menuItems.map(item => (
          <li key={item.href}>
            <MenuLink
              href={item.href}
              icon={item.icon}
              color={item.color || 'purple'}
              title={item.title}
              description={item.description}
              active={
                item.href?.split('/')?.length === 2
                  ? Boolean(pathname === item.href)
                  : Boolean(pathname.startsWith(item.href))
              }
              expanded={isOpen}
            />
          </li>
        ))}
      </ul>
      <div className="flex items-start justify-start mt-auto">
        <ul className="flex flex-col items-stretch justify-start mt-12">
          {secondaryItems.map(item => (
            <li key={item.href}>
              <MenuLink
                href={item.href}
                icon={item.icon}
                color={item.color || 'purple'}
                title={item.title}
                description={item.description}
                active={Boolean(pathname.includes(item.href))}
                expanded={isOpen}
                small
              />
            </li>
          ))}
        </ul>
      </div>
      {/* <div className="flex items-start justify-start mt-4">
        <button
          onClick={() => (isExpanded ? handleCollapse() : handleExpand())}
          title={isExpanded ? 'Collapse menu' : 'Keep menu open'}
          className={`flex items-center justify-start gap-4 ${isExpanded ? 'opacity-100' : 'opacity-50'} transition-opacity`}>
          <IconBox
            icon={isExpanded ? 'sidebar_locked' : 'sidebar'}
            color="purple"
            size="sm"
          />
          <span
            className={`whitespace-nowrap text-xs text-4 hover:text-1 ${isOpen ? 'block' : 'hidden'}`}>
            {isExpanded ? 'Collapse menu' : 'Keep menu open'}
          </span>
        </button>
      </div> */}
    </nav>
  );
}
