'use client';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import Link from '@/components/Link';
import IconBox from '@/components/IconBox';

const ORGS_LIMIT = 5;

export default function OrgsDropdown({ orgs, org, action, expanded = true }) {
  const hasMore = orgs.length > ORGS_LIMIT;
  orgs = orgs.slice(0, ORGS_LIMIT);

  let canCreateOrg = true;
  orgs?.forEach(org => {
    if (org.paused) {
      canCreateOrg = false;
    }
  });

  let secondary = [];
  if (hasMore) {
    secondary = [
      {
        id: 'ellipsis',
        name: 'View All',
        href: '/orgs',
        icon: 'ellipsis',
      },
    ];
  }
  if (canCreateOrg) {
    secondary.push({
      id: 'create',
      name: 'Create Org',
      href: '/orgs/create',
      icon: 'add',
    });
  }

  return (
    <Popover className="relative group">
      <PopoverButton>
        <Link
          href="/dashboard"
          className="flex items-center gap-3 h-12 py-2 font-medium opacity-60 hover:opacity-100 flex-shrink-0">
          <div className="block group-hover:hidden group-focus-within:hidden">
            <IconBox icon="organisation" color="purple" size="lg" />
          </div>
          <div className="hidden group-hover:block group-focus-within:block">
            <IconBox icon="expand" color="purple" size="lg" />
          </div>
          <div
            className={`${expanded ? 'flex' : 'hidden'} flex-col items-start text-left`}>
            <span className="block text-base whitespace-nowrap truncate w-44">
              {org?.name || 'Select Org'}
            </span>
            <span className="block text-xs text-4 whitespace-nowrap truncate w-44">
              {org?.id}
            </span>
          </div>
        </Link>
      </PopoverButton>

      <PopoverPanel
        static
        className="absolute -left-3 z-10 mt-0 flex -translate-x-4 px-4 transition translate-y-1 opacity-0 pointer-events-none group-hover:opacity-100 group-focus-within:opacity-100 group-hover:translate-y-0 group-focus-within:translate-y-0 group-hover:pointer-events-auto group-focus-within:pointer-events-auto">
        <div className="w-auto overflow-hidden rounded-xl bg-dark-400 ring-1 ring-brand-purple/5 lg:max-w-3xl">
          <div className="grid grid-cols-1 w-80 py-1">
            {orgs.map(org => (
              <form key={org.id} action={() => action({ org_id: org.id })}>
                <button
                  className="group relative w-full flex flex-row flex-shrink-0 items-center gap-x-3 rounded-lg px-3 py-3 hover:bg-dark-500 opacity-80 hover:opacity-100 text-3 hover:text-1"
                  type="submit">
                  <IconBox
                    icon={org.icon || 'organisation'}
                    color="purple"
                    size="lg"
                  />
                  <div className="font-medium text-base leading-6 text-left whitespace-nowrap overflow-hidden text-ellipsis">
                    {org?.name || org?.alias || org?.id}
                    <span className="absolute inset-0" />
                  </div>
                </button>
              </form>
            ))}
            {secondary.map(item => (
              <div
                key={item.id}
                className="group relative flex flex-shrink-0 items-start gap-x-3 rounded-lg pl-4 pr-3 py-3 hover:bg-dark-500">
                <IconBox icon={item.icon} color="purple" size="md" />
                <div>
                  <Link
                    href={item.href}
                    className="font-medium text-4 hover:text-2 text-sm leading-6">
                    {item.name}
                    <span className="absolute inset-0" />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </PopoverPanel>
    </Popover>
  );
}
