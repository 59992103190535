'use client';

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/react';
import Link from '@/components/Link';
import IconBox from '@/components/IconBox';
import { usePathname } from 'next/navigation';

const PROJECT_LIMIT = 5;
const REDIRECT_PATHS = [
  'media',
  'tasks',
  'automations',
  'access',
  'players',
  'events',
  'settings',
];

export default function ProjectsDropdown({ projects, project, expanded }) {
  const pathname = usePathname();

  const hasMore = projects.length > PROJECT_LIMIT;
  projects = projects
    .sort((a, b) => new Date(b.updated) - new Date(a.updated))
    .slice(0, PROJECT_LIMIT);

  let secondary = [];
  if (hasMore) {
    secondary = [
      {
        id: 'ellipsis',
        name: 'View All',
        href: '/projects',
        icon: 'ellipsis',
      },
    ];
  }
  secondary.push({
    id: 'create',
    name: 'Create Project',
    href: '/projects/create',
    icon: 'add',
  });

  return (
    <Popover className="relative group">
      <PopoverButton>
        <Link
          href={`/${project?.alias || project?.id}`}
          className="flex items-center gap-3 h-12 py-2 font-medium opacity-60 hover:opacity-100 flex-shrink-0">
          <div className="block group-hover:hidden group-focus-within:hidden">
            <IconBox icon="project" color="purple" size="lg" />
          </div>
          <div className="hidden group-hover:block group-focus-within:block">
            <IconBox icon="expand" color="purple" size="lg" />
          </div>
          <div
            className={`${expanded ? 'flex' : 'hidden'} flex-col items-start text-left`}>
            <span className="block text-base whitespace-nowrap truncate w-44">
              {project?.name || 'Select Project'}
            </span>
            <span className="block text-xs text-4 whitespace-nowrap truncate w-44">
              {project?.id}
            </span>
          </div>
        </Link>
      </PopoverButton>

      <PopoverPanel
        static
        className="absolute -left-3 z-10 mt-0 flex -translate-x-4 px-4 transition translate-y-1 opacity-0 pointer-events-none group-hover:opacity-100 group-focus-within:opacity-100 group-hover:translate-y-0 group-focus-within:translate-y-0 group-hover:pointer-events-auto group-focus-within:pointer-events-auto">
        <div className="w-auto overflow-hidden rounded-xl bg-dark-400 ring-1 ring-brand-purple/5 lg:max-w-3xl">
          <div className="grid grid-cols-1 w-80 py-1">
            {projects.map(proj => {
              let redirect_url = `/${proj.alias}`;
              REDIRECT_PATHS.forEach(path => {
                if (pathname.startsWith(`/${project.alias}/${path}`)) {
                  redirect_url = `/${proj.alias}/${path}`;
                }
              });
              return (
                <Link
                  key={proj.id}
                  href={redirect_url}
                  className="group relative w-full flex flex-row flex-shrink-0 items-center gap-x-3 rounded-lg px-3 py-3 hover:bg-dark-500 opacity-80 hover:opacity-100 text-3 hover:text-1">
                  <IconBox
                    icon={proj.icon || 'project'}
                    color="purple"
                    size="lg"
                  />
                  <div className="font-medium text-base leading-6 text-left whitespace-nowrap overflow-hidden text-ellipsis">
                    {proj?.name || proj?.alias || proj?.id}
                    <span className="absolute inset-0" />
                  </div>
                </Link>
              );
            })}

            {secondary.map(item => (
              <div
                key={item.id}
                className="group relative flex flex-shrink-0 items-start gap-x-3 rounded-lg pl-4 pr-3 py-3 hover:bg-dark-500">
                <IconBox icon={item.icon} color="purple" size="md" />
                <div>
                  <Link
                    href={item.href}
                    className="font-medium text-4 hover:text-2 text-sm leading-6">
                    {item.name}
                    <span className="absolute inset-0" />
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </PopoverPanel>
    </Popover>
  );
}
