'use client';

import { useFrontChatBoot } from 'front-chat-sdk/hooks';
import { useEffect, useState } from 'react';
import Icon from './Icon';
const chatId = '855b94f4a2ec71739b4ab188083a83ac';

export default function FrontChat({ primary = false }) {
  const { frontChat, initialize, isInitialized } = useFrontChatBoot(
    typeof document !== 'undefined' ? document?.body : null,
  );

  const [isWindowVisible, setIsWindowVisible] = useState(false);

  // Example of using useFrontChat to initialize the chat widget when a component mounts.
  useEffect(() => {
    if (!initialize || isInitialized) {
      return;
    }

    initialize({
      chatId,
      useDefaultLauncher: false,
      shouldHideExpandButton: true,
    });
  }, [isInitialized, initialize]);

  // NOTE: You can also start the widget with the chat window visible by providing the `shouldShowWindow`
  // option to the 'init' command. The below effect is just an example of waiting for the chat widget to
  // be initialized, before performing another action.
  useEffect(() => {
    if (!frontChat || !isInitialized || isWindowVisible) {
      return;
    }

    // frontChat('show');
    setIsWindowVisible(true);
  }, [frontChat, isInitialized, isWindowVisible]);

  function openChat() {
    frontChat('show');
  }

  return (
    <button
      className={`group fixed bottom-4 right-4 p-2 md:p-3 text-white rounded-full z-50 opacity-80 hover:opacity-100 transition-opacity duration-300 ${primary ? 'bg-brand-500' : 'bg-dark-500'}`}
      onClick={openChat}>
      <Icon
        icon="support"
        className={`w-8 h-8 md:w-12 md:h-12 ${primary ? 'text-1' : 'text-3'} group-hover:text-1 transition-all duration-300`}
      />
    </button>
  );
}
