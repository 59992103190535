// green
// blue
// magenta
// orange
// yellow

function getProjectMenuItems({ project_alias }) {
  const items = [
    // {
    //   title: 'Project',
    //   description: "Get an overview of your project's activity",
    //   icon: 'dashboard',
    //   color: 'purple',
    //   href: `/${project_alias}`,
    //   dashboardItem: false,
    // },
    {
      title: 'Media',
      description: "View and manage your project's media",
      icon: 'media',
      color: 'blue',
      href: `/${project_alias}/media`,
      dashboardItem: true,
    },
    {
      title: 'Tasks',
      description: "View and manage your project's tasks",
      icon: 'transform',
      color: 'pink',
      href: `/${project_alias}/tasks`,
      dashboardItem: true,
    },
    {
      title: 'Automations',
      description: "View and manage your project's automations",
      icon: 'automation',
      color: 'yellow',
      href: `/${project_alias}/automations`,
      dashboardItem: true,
    },
    {
      title: 'Access Rules',
      description: "View and manage your project's access rules",
      icon: 'security',
      color: 'orange',
      href: `/${project_alias}/access`,
      dashboardItem: true,
    },
    {
      title: 'Players',
      description: "View and manage your project's players",
      icon: 'player',
      color: 'magenta',
      href: `/${project_alias}/players`,
      dashboardItem: true,
    },
    {
      title: 'Events',
      description: "View your project's logs and events",
      icon: 'stats',
      color: 'green',
      href: `/${project_alias}/events`,
      dashboardItem: true,
      secondary: true,
    },
    {
      title: 'Settings',
      description:
        "Update your project's name, description, and other settings",
      icon: 'settings',
      color: 'purple',
      href: `/${project_alias}/settings`,
      dashboardItem: true,
      secondary: true,
    },
  ];
  return items;
}

export default getProjectMenuItems;
